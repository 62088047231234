body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf4fc;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #880e4f77 #eae5ec;
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eae5ec;
}

::-webkit-scrollbar-thumb {
  background-color: #880e4f77;
  border-radius: 4px;
}
